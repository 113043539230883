<nz-layout >
  <nz-sider
    nzCollapsible
    [(nzCollapsed)]="isCollapsed"
    [nzWidth]="width"
    [nzCollapsedWidth]="collapsedWidth"
    [nzReverseArrow]="isReverseArrow"
  >
    <div class="logo"></div>
    <ul nz-menu nzTheme="dark" nzMode="inline">
      <li nz-menu-item (click)="clickNav('/top-recipes')">
        <i nz-icon nzType="appstore"></i>
        <span>Top Recipes</span>
      </li>
      <li nz-menu-item (click)="clickNav('/add-recipe')">
        <i nz-icon nzType="shopping-cart"></i>
        <span>Add Recipe</span>
      </li>
      <li nz-menu-item (click)="clickNav('/home-inventory')">
        <i nz-icon nzType="home"></i>
        <span>Home Inventory</span>
      </li>
      <li nz-menu-item (click)="clickNav('/update-ingredients')">
        <i nz-icon nzType="shopping-cart"></i>
        <span>Ingredient Prices</span>
      </li>
      <li nz-menu-item (click)="clickNav('/ingredient-form')">
        <i nz-icon nzType="shopping-cart"></i>
        <span>Recipe Ingredients</span>
      </li>
      <li nz-menu-item (click)="clickNav('/shopping-list')">
        <i nz-icon nzType="shopping-cart"></i>
        <span>Shopping List</span>
      </li>
      <li nz-menu-item (click)="clickNav('/ingredients-by-price')">
        <i nz-icon nzType="dollar"></i>
        <span>Ingredients By Price</span>
      </li>
      <li nz-menu-item (click)="clickNav('/contact-info')">
        <i nz-icon nzType="appstore"></i>
        <span>Contact Info</span>
      </li>
      <li nz-menu-item (click)="clickNav('/logout')">
        <i nz-icon nzType="dollar"></i>
        <span>Logout</span>
      </li>

    </ul>
  </nz-sider>
  <nz-layout class="layout"  >
    <nz-header>
      <div class="logo"
           [style.background]="'url(assets/img/leaf.png)'"></div>
      <div class="logo-text">Recipe Form</div>

      <!--    <ul nz-menu nzTheme="dark" nzMode="horizontal">-->
      <!--      <li nz-menu-item>nav 1</li>-->
      <!--    </ul>-->
    </nz-header>
    <nz-content>
      <!--    <nz-breadcrumb>-->
      <!--      <nz-breadcrumb-item>Home</nz-breadcrumb-item>-->
      <!--      <nz-breadcrumb-item>List</nz-breadcrumb-item>-->
      <!--      <nz-breadcrumb-item>App</nz-breadcrumb-item>-->
      <!--    </nz-breadcrumb>-->
      <div class="inner-content" >

        <div style="clear: both; height: 40px;"></div>
        <router-outlet></router-outlet>

      </div>
    </nz-content>

  </nz-layout>
</nz-layout>
