<form nz-form >
  <nz-form-item>
    <nz-col [nzSm]="6" [nzXs]="24"></nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <h2>Create Recipe</h2>
    </nz-col>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">Title</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <input nz-input
             [(ngModel)]="recipeItem.title"
             name="title"
             id="title"
             [style.border]="(titleInvalid) ? 'solid 1px red' : ''"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">Protein</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <nz-select name="proteinId" [(ngModel)]="recipeItem.protein_id" [style.border]="(proteinInvalid) ? 'solid red 1px' : ''">
        <ng-container *ngFor="let protein of proteinItems">
          <nz-option [nzValue]="protein.id" [nzLabel]="protein.title"></nz-option>
        </ng-container>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">Style</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <nz-select name="recipeStyleId" [(ngModel)]="recipeItem.recipe_style_id" >
        <ng-container *ngFor="let style of recipeStyleItems">
          <nz-option [nzValue]="style.id" [nzLabel]="style.title"></nz-option>
        </ng-container>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">Rating</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <nz-select name="tasteLevelId" [(ngModel)]="recipeItem.taste_level_id" >
        <ng-container *ngFor="let taste_level of tasteLevelItems">
          <nz-option [nzValue]="taste_level.id" [nzLabel]="taste_level.title"></nz-option>
        </ng-container>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">Difficulty Level</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <nz-select name="difficultyLevelId" [(ngModel)]="recipeItem.difficulty_level_id">
        <ng-container *ngFor="let difficultyLevel of difficultyLevelItems">
          <nz-option [nzValue]="difficultyLevel.id" [nzLabel]="difficultyLevel.title"></nz-option>
        </ng-container>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">Recipe Link</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <input nz-input
             [(ngModel)]="recipeItem.recipe_link"
             name="recipe_link"
             id="recipe_link"
             [style.border]="(titleInvalid) ? 'solid 1px red' : ''"
      />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">Last Date Made</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <nz-date-picker
        name="lastDateMade"
        [(ngModel)]="recipeItem.last_date_made"
        (ngModelChange)="onDateChange($event)"
        [nzFormat]="'MM/dd/yyyy'"
      ></nz-date-picker>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-col [nzSm]="6" [nzXs]="24"></nz-col>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <label nz-checkbox name="containsSalad" [(ngModel)]="recipeItem.contains_salad">Contains Salad</label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-col [nzSm]="6" [nzXs]="24"></nz-col>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <label nz-checkbox name="containsGluten" [(ngModel)]="recipeItem.contains_gluten">Contains Gluten</label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-col [nzSm]="6" [nzXs]="24"></nz-col>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <label nz-checkbox name="isHomeChef" [(ngModel)]="recipeItem.is_homechef">Is Home Chef Recipe</label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-col [nzSm]="6" [nzXs]="24"></nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <button nz-button nzType="primary" (click)="submitForm()">Create</button>
    </nz-col>
  </nz-form-item>
</form>
