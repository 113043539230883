
<form nz-form >
  <nz-form-item>
    <nz-col [nzSm]="6" [nzXs]="24"></nz-col>
    <nz-col [nzSm]="14" [nzXs]="24">
      <h2>Modify Recipe Ingredients</h2>
    </nz-col>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">Recipe</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" >
      <app-select-recipe
        (onSelection)="selectedRecipe($event)"
        [currentRecipe]="recipeItem"
      ></app-select-recipe>
      <ng-container *ngIf="recipeItem && recipeItem.id">
        <a [routerLink]="'/edit-recipe/' + recipeItem.id" nz-button nzType="default"><i nz-icon nzType="edit" nzTheme="outline"></i></a>
      </ng-container>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email">Ingredient</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" >


      <nz-select
        #ingredientSelect
        [(ngModel)]="selectedIngredientItems"
        (ngModelChange)="updateIngredientItems($event)"
        [nzSize]="selectedIngredientSize"
        nzMode="multiple"
        nzShowSearch
        nzAllowClear
        name="ingredientItem"
        nzPlaceHolder="Select Ingredients"
        [nzDropdownRender]="addItemRender"
      >
        <ng-container *ngFor="let ingredient of ingredientItems" >
          <nz-option [nzLabel]="ingredient.title" [nzValue]="ingredient"></nz-option>
        </ng-container>
      </nz-select>
      <ng-template #addItemRender>
        <nz-divider style="margin: 2px 0;"></nz-divider>
        <div
          nz-popover
          nzPopoverTitle="New Ingredient"
          [(nzVisible)]="showPopover"
          nzPopoverTrigger="click"
          [nzPopoverContent]="newIngredient"
          style="padding: 8px; cursor: pointer"
        >
          <i nz-icon nzType="plus"></i> Add new ingredient</div>
      </ng-template>
      <ng-template #newIngredient>
        <nz-input-group [nzAddOnAfter]="suffixIconButton">
          <input nz-input placeholder="New Ingredient" name="newIngredientTitle" [(ngModel)]="newIngredientTitle" [ngModelOptions]="{standalone: true}"/>
        </nz-input-group>
      </ng-template>
      <ng-template #suffixIconButton>
        <i nz-icon nzType="folder-add" nzTheme="outline" (click)='addNewIngredient()' style='cursor: pointer;'></i>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <ng-container *ngIf="recipeItem.id" >
    <nz-form-item>
      <nz-col [nzSm]="6" [nzXs]="24"></nz-col>
      <nz-col [nzSm]="14" [nzXs]="24">
        <nz-table
            #basicTable
            [nzData]="recipeItem.riRecipeIngredients"
            [nzPageSize]="1000000"
            [nzPageIndex]="1"
            [nzShowPagination]="false"
        >
          <thead>
          <tr>
            <th>Ingredient List</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of basicTable.data">
            <td>{{ data.ingredient.title }}</td>
            <td><i nz-icon nzType="delete" nzTheme="outline" (click)="removeIngredient(data.ingredient.id)"></i></td>
          </tr>
          </tbody>
        </nz-table>
      </nz-col>
    </nz-form-item>
  </ng-container>
</form>
